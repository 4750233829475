@font-face {
font-family: '__inter_53dc39';
src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__inter_Fallback_53dc39';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_53dc39 {font-family: '__inter_53dc39', '__inter_Fallback_53dc39'
}.__variable_53dc39 {--font-inter: '__inter_53dc39', '__inter_Fallback_53dc39'
}

@font-face {
font-family: '__jetBrainsMono_75cbef';
src: url(/_next/static/media/bb3ef058b751a6ad-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__jetBrainsMono_Fallback_75cbef';src: local("Arial");ascent-override: 77.57%;descent-override: 22.82%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_75cbef {font-family: '__jetBrainsMono_75cbef', '__jetBrainsMono_Fallback_75cbef'
}.__variable_75cbef {--font-jetbrains-mono: '__jetBrainsMono_75cbef', '__jetBrainsMono_Fallback_75cbef'
}

